import React from "react"
import { Link } from "gatsby"

import * as style from "./category.module.scss"
import FlickrThumbnail from "./flickr/flickrthumbnail"

const Category = ({ category }) => {
  return (
    <div className={style.category}>
      <Link to={category.path}>
        <FlickrThumbnail cat={category.thumbnail} />
        <h2>{category.name}</h2>
        <p>
          <strong>Categories: </strong>
          {category.includes}
        </p>
      </Link>
    </div>
  )
}

export default Category
