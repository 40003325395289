import { graphql } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Category from "../components/category"

import * as style from "./index.module.scss"

const PhotographyPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Photography" />
      <>
        <div className={style.content}>
          <h1>Photography</h1>
          <p>
            The galleries created are be based on photography categories, but
            not every genre will have a gallery. Similar types might be in a
            single gallery.
          </p>
          <p>
            The photos are in my Flickr account to avoid duplication. I do not
            have to maintain storage on this website. The tags in Flickr will
            determine the images seen in the gallery. Please allow time for the
            building of the gallery you want to see.
          </p>
          <p>
            I have not updated Flickr yet, so the gallery pages will only
            contain the thumbnail from the individual category.
          </p>
        </div>
        <section className={style.categories}>
          {data.allCategoriesYaml.nodes.map(category => (
            <Category
              sitepath={data.site.siteMetadata.siteUrl}
              key={category.id}
              category={category}
            />
          ))}
        </section>
      </>
    </Layout>
  )
}

export default PhotographyPage

export const query = graphql`
  {
    allCategoriesYaml(sort: { fields: name }) {
      nodes {
        id
        name
        includes
        description
        path
        thumbnail
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
