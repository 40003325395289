import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { APIURL, APIKEY, APIOWNER, PHOTO } from "../../config"
import GetThumbnail from "./getthumbnail"

import * as style from "./getthumbnail.module.scss"

function FlickrThumbnail({ cat }) {
  const [flickrPhoto, setFlickrPhoto] = useState(null)

  useEffect(() => {
    const tags = cat
    fetch(
      `${APIURL}?method=flickr.photos.search&api_key=${APIKEY}&user_id=${APIOWNER}&tags=${tags}&tag_mode=all&format=json&nojsoncallback=1`
    )
      .then(response => response.json())
      .then(setFlickrPhoto)
  }, [cat])

  if (flickrPhoto) {
    if (cat.includes("thumbnail")) {
      var photo_id = flickrPhoto.photos.photo[0].id
      return (
        <>
          <GetThumbnail photo={photo_id} />
        </>
      )
    } else {
      return (
        <>
          {flickrPhoto.photos.photo.map(props => (
            <div className="photo" key={props.id}>
              <Link to={`${PHOTO}${props.id}`} target="_blank">
                <GetThumbnail photo={props.id} />
              </Link>
            </div>
          ))}
        </>
      )
    }
  }

  return (
    <>
      <div className={style.placeholder}>Loading ...</div>
    </>
  )
}

export default FlickrThumbnail
