import React, { useEffect, useState } from "react"
import { APIURL, APIKEY, IMGSOURCE } from "../../config"

import * as style from "./getthumbnail.module.scss"

function GetThumbnail(data) {
  const [flickrMedium, setFlickrMedium] = useState(null)

  useEffect(() => {
    fetch(
      `${APIURL}?method=flickr.photos.getInfo&api_key=${APIKEY}&photo_id=${data.photo}&format=json&nojsoncallback=1`
    )
      .then(response => response.json())
      .then(setFlickrMedium)
  }, [data])

  if (flickrMedium) {
    var srcPath =
      IMGSOURCE +
      flickrMedium.photo.server +
      "/" +
      flickrMedium.photo.id +
      "_" +
      flickrMedium.photo.secret +
      "_z.jpg"
    return (
      <>
        <img src={srcPath} alt={flickrMedium.photo.description._content} />
      </>
    )
  }

  return (
    <>
      <div className={style.placeholder}>Loading ...</div>
    </>
  )
}

export default GetThumbnail
